import React, { useEffect, useRef, useState } from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AppBar from '@mui/material/AppBar';
import SendIcon from '@mui/icons-material/Send';
import PlayIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';


import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import MenuIcon from '@mui/icons-material/Menu';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Drawer from '@mui/material/Drawer';

import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slider from '@mui/material/Slider';

const darkTheme = createTheme({
  palette: { mode: "dark" },
  typography: { fontSize: 14, h1: { fontSize: 32 } },
});

export default function App() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openKeyboard, setOpenKeyboard] = useState(false);

  const [formula, setFormula] = useState("sin(x / 15) * cos(y / 40) * tan(z)");
  const [z, setZ] = useState(1);
  const [zoom, setZoom] = useState(10);

  const [color, setColor] = useState("#FFFFFF");
  const [backgroundColor, setBackgroundColor] = useState("#121212");

  const [play, setPlay] = useState<NodeJS.Timer | null>(null);

  const [error, setError] = useState<Error | null>(null);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const renderRef = useRef<() => void>(() => { });

  function onFormula(part: string) {
    setFormula(f => `${f}${part}`);
  }

  function onRender() {
    if (canvasRef.current) {
      try {
        render(canvasRef.current, formula, z, zoom, color, backgroundColor);
      } catch (e) {
        setError(e as Error);
      }
    }
  }
  renderRef.current = onRender;

  function onPlay() {
    const interval = setInterval(() => {
      setZ(z => {
        let newZ = z + 1;
        if (newZ > 100) newZ = 0;
        return newZ;
      });
      // TODO
      renderRef.current();
    }, 1000);
    setPlay(interval);
  }
  function onStop() {
    if (play) clearInterval(play);
    setPlay(null);
  }

  // render after open
  useEffect(() => {
    setTimeout(onRender, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ThemeProvider theme={darkTheme}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: "column",
        width: '100%',
        height: "100vh",
        bgcolor: 'background.default',
        color: 'text.primary',
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            onClick={() => setOpenDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ minWidth: 200 }}
          >
            Mathematic Explorer
          </Typography>
          <TextField
            fullWidth
            label="Formula"
            sx={{ mx: 2 }}
            size="small"
            value={formula}
            onChange={e => setFormula(e.target.value)}
          />
          <IconButton
            size="large"
            color="inherit"
            onClick={() => setOpenKeyboard(k => !k)}
          >
            <KeyboardIcon />
          </IconButton>
          <IconButton
            size="large"
            color="inherit"
            onClick={() => {
              setOpenKeyboard(false)
              onRender();
            }}
          >
            <SendIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="persistent"
        anchor="bottom"
        open={openKeyboard}
      >
        <Box sx={{ m: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
          <IconButton sx={{ position: "absolute", top: 1, right: 4 }} onClick={() => {
            setOpenKeyboard(false);
          }}>
            <KeyboardArrowDownIcon />
          </IconButton>
          <Stack spacing={2} direction="column">
            <Stack spacing={2} direction="row">
              <Button variant="contained" onClick={() => onFormula(" + ")}>+</Button>
              <Button variant="contained" onClick={() => onFormula(" - ")}>-</Button>
              <Button variant="contained" onClick={() => onFormula(" * ")}>*</Button>
              <Button variant="contained" onClick={() => onFormula(" / ")}>/</Button>
              <Button variant="contained" onClick={() => onFormula(" % ")}>%</Button>
            </Stack>
            <Stack spacing={2} direction="row">
              <Button variant="contained" onClick={() => onFormula("sin(x)")}>sin(x)</Button>
              <Button variant="contained" onClick={() => onFormula("cos(x)")}>cos(x)</Button>
              <Button variant="contained" onClick={() => onFormula("tan(x)")}>tan(x)</Button>
              <Button variant="contained" onClick={() => onFormula("sinh(x)")}>sinh(x)</Button>
              <Button variant="contained" onClick={() => onFormula("cosh(x)")}>cosh(x)</Button>
            </Stack>
            <Stack spacing={2} direction="row">
              <Button variant="contained" onClick={() => onFormula("log(x)")}>log(x)</Button>
              <Button variant="contained" onClick={() => onFormula("exp(x)")}>exp(x)</Button>
              <Button variant="contained" onClick={() => onFormula("pow(x, y)")}>pow(x, y)</Button>
              <Button variant="contained" onClick={() => onFormula("sqrt(x, y)")}>sqrt(x, y)</Button>
            </Stack>
            <Stack spacing={2} direction="row">
              <Button variant="contained" onClick={() => onFormula("abs(x)")}>abs(x)</Button>
              <Button variant="contained" onClick={() => onFormula("fib(x)")}>fib(x)</Button>
            </Stack>
          </Stack>
        </Box>
      </Drawer>

      <Drawer
        variant="temporary"
        anchor="left"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
          onRender();
        }}
      >
        <Box sx={{ width: 400, display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={() => {
            setOpenDrawer(false);
            onRender();
          }}>
            <ChevronLeftIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ mx: 2 }}>
          <Typography gutterBottom>Zoom ({zoom})</Typography>
          <Slider min={1} max={100} value={zoom} valueLabelDisplay="auto" onChange={(e, v) => setZoom(Number(v))} />

          <TextField sx={{ my: 1 }} fullWidth label="Text color" type="color" variant="standard" value={color} onChange={(e) => setColor(e.target.value)} />

          <TextField sx={{ my: 1 }} fullWidth label="Background color" type="color" variant="standard" value={backgroundColor} onChange={(e) => setBackgroundColor(e.target.value)} />
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Typography gutterBottom sx={{ pl: 1 }}>Created by Filip Paulů, Ph.D.</Typography>
      </Drawer>

      <Box sx={{ ml: 2, mr: 4, display: "flex", alignItems: "center" }}>
        <IconButton onClick={() => play ? onStop() : onPlay()}>
          {play ? <PauseIcon /> : <PlayIcon />}
        </IconButton>
        <Typography gutterBottom sx={{ px: 2, pt: 1 }}>Z</Typography>
        <Slider min={0} max={100} value={z} valueLabelDisplay="auto" onChange={(e, v) => setZ(Number(v))} onChangeCommitted={() => onRender()} />
      </Box>

      <Box sx={{ mb: 3, flexGrow: 1 }}>
        <canvas ref={canvasRef} style={{ width: "100%", height: "100%" }} />
      </Box>

      <Snackbar open={error !== null} autoHideDuration={12000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error !== null && error.message}
        </Alert>
      </Snackbar>
    </Box>
  </ThemeProvider >;
}

function render(canvas: HTMLCanvasElement, formula: string, z: number, zoom: number, numberColor: string, backgroundColor: string) {
  const cellSize = [70, 20];
  const cellPadding = [8, 2];

  const xOffset = 0;
  const yOffset = 0;

  canvas.width = 0;
  canvas.height = 0;
  const { width, height } = canvas.getBoundingClientRect();

  const ctx = canvas.getContext("2d");
  if (!ctx) throw new Error("Browser doesn't support Canvas 2D drawing.");

  canvas.width = width;
  canvas.height = height;

  // fill background
  ctx.beginPath();
  ctx.rect(0, 0, width, height);
  ctx.fillStyle = backgroundColor;
  ctx.fill();

  const cellWidth = cellSize[0] / zoom;
  const cellHeight = cellSize[1] / zoom;

  ctx.font = `${Math.ceil((cellSize[1] - 2 * cellPadding[1]) / zoom)}px Arial`;
  ctx.fillStyle = numberColor;

  const fib = fibonacci;

  const sin = Math.sin;
  const cos = Math.cos;
  const tan = Math.tan;
  const log = Math.log;
  const pow = Math.pow;
  const exp = Math.exp;
  const abs = Math.abs;
  const sqrt = Math.sqrt;
  const sinh = Math.sinh;
  const cosh = Math.cosh;

  function execFormula(x: number, y: number): string {
    const number: number = eval(formula);
    const absNumber = abs(number);
    // return number.toLocaleString();
    // return number.toFixed(2);

    if (absNumber > 1000000000000000) {
      return number.toExponential(1);
    } else if (absNumber > 1000000000000) {
      return Math.round(number / 1000000000000) + "T";
    } else if (absNumber > 1000000000) {
      return Math.round(number / 1000000000) + "G";
    } else if (absNumber > 1000000) {
      return Math.round(number / 1000000) + "M";
    } else if (absNumber > 1000) {
      return Math.round(number / 1000) + "k";
    } else {
      return (Math.round(number * 1000) / 1000).toLocaleString();
    }
  }

  // numbers
  for (let x = 0; x * cellWidth < width; x++) {
    for (let y = 0; y * cellHeight < height; y++) {
      ctx.fillText(
        execFormula(x + xOffset, y + yOffset),
        (x * cellSize[0] + cellPadding[0]) / zoom,
        (cellSize[1] + y * cellSize[1] - 2 * cellPadding[1]) / zoom
      );
    }
  }
}

const memoFibonacci: number[] = [];
function fibonacci(num: number): number {
  if (memoFibonacci[num]) return memoFibonacci[num];
  if (num <= 1) return 1;

  return memoFibonacci[num] = fibonacci(num - 1) + fibonacci(num - 2);
}
